import React, { useState } from "react";
import mainlogo from "./2.png";
import community from "./community.png";
import communityonhover from "./communityonhover.png";
import "./Navbarnew.scss";
import hamburger from "./menu.png";
import { Link, useNavigate } from "react-router-dom";

const Navbarnew = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [hamburgerState, setHamburgerState] = useState(false);
  const navigate = useNavigate();
  const handleHover = () => {
    setIsHovered(!isHovered);
  };
  return (
    <div id="mainheaderdiv">
      <div id="headerlogodiv">
        <Link to="/">
          <img src={mainlogo} alt="" />
        </Link>
      </div>
      <div id="headermiddiv">
        <ul id="headerlist">
          <Link to="/">
            <li className="navbar_text hover-underline-animation">Home</li>
          </Link>

          <Link to="/about">
            <li className="navbar_text hover-underline-animation">About</li>
          </Link>

          <Link to="/members">
            <li className="navbar_text hover-underline-animation">
              Membership
            </li>
          </Link>

          <Link to="/event">
            <li className="navbar_text hover-underline-animation">Event</li>
          </Link>
          <Link to="/contactus">
            <li className="navbar_text hover-underline-animation">
              Contact Us
            </li>
          </Link>
        </ul>
      </div>
      {/* <Link to="/member"> */}
      <div
        id="applydiv"
        onClick={() => navigate("/member")}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
      >
        <img src={isHovered ? communityonhover : community} alt=" " />
        <p>Apply for Membership</p>
      </div>
      {/* </Link> */}
      <img
        id="hamburgerimg"
        src={hamburger}
        alt=""
        onClick={() => setHamburgerState(!hamburgerState)}
      />

      <ul className={`hamburger${hamburgerState ? "" : " ham-hide"}`}>
        <Link to="/" onClick={() => setHamburgerState(!hamburgerState)}>
          <li className="navbar_text hamfont">Home</li>
        </Link>

        <Link to="/about" onClick={() => setHamburgerState(!hamburgerState)}>
          <li className="navbar_text hamfont">About</li>
        </Link>

        <Link to="/members" onClick={() => setHamburgerState(!hamburgerState)}>
          <li className="navbar_text hamfont">Members</li>
        </Link>

        <Link to="/event" onClick={() => setHamburgerState(!hamburgerState)}>
          <li className="navbar_text hamfont">Event</li>
        </Link>
        <Link
          to="/contactus"
          onClick={() => setHamburgerState(!hamburgerState)}
        >
          <li className="navbar_text hamfont">Contact Us</li>
        </Link>
        <Link to="/" onClick={() => setHamburgerState(!hamburgerState)}>
          <li className="navbar_text hamfont">Apply for membership</li>
        </Link>
      </ul>
    </div>
  );
};

export default Navbarnew;
