import React from "react";
import "./ContactUs.scss";
import ContactForm from "./ContactForm";

const ContactUs = () => {
  return (
    <div>
      <ContactForm />
    </div>
  );
};

export default ContactUs;
