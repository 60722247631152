import "./App.scss";
import "./components/Home/Key.scss";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AboutUs from "./components/AboutUs/AboutUs";
import Blogs from "./components/Blogs/Blogs";
import ContactUs from "./components/ContactUs/ContactUs";
import Footer from "./components/Footer/Footer";
import Navbarnew from "./components/Header/Navbarnew";
import Home from "./components/Home";
import "./components/Home/global.scss";
import MembershipPerks from "./components/Membership/MembershipPerks";
import NewMember from "./components/Membership/NewMember";
import EventPage from "./components/Upevents/EventPage";
import NewEvent from "./components/Upevents/NewEvent";
import Upevents from "./components/Upevents/Upevents";

import AllApplications from "./components/Dashboard/Applications/AllApplications";
import Loginpage from "./components/Dashboard/loginpage";
import AppDetails from "./components/Dashboard/Applications/AppDetails";
import Contentbox from "./components/Dashboard/Contentbox";
import Dashboard from "./components/Dashboard/Dashboard";
import Otp from "./components/Membership/Otp";
import NotFound from "./components/NotFound";
import PasEvents from "./components/Upevents/PasEvents";
import "./components/Upevents/Upevents.css";
import AllEvents from "./components/Upevents/AllEvents";
import AdminEvent from "./components/Upevents/AdminEvent";
import EditEvents from "./components/Upevents/EditEvents";
import MemberEdit from "./components/Membership/MemberEdit";
import AdminActivity from "./components/Dashboard/admin/AdminActivity";
import CreateUser from "./components/Dashboard/CreateUser";
import ForgetPass from "./components/Dashboard/ForgetPass";
import Forget from "./components/Dashboard/Forget";

function App() {
  return (
    <Router>
      <Navbarnew />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/event" element={<EventPage />} />
        <Route path="/members" element={<MembershipPerks />} />
        <Route path="/member" element={<NewMember />} />
        {/* <Route path="/member/:token" element={<NewMember />} /> */}
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/Upevents/:id" element={<Upevents />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/past/:id" element={<PasEvents />} />
        <Route path="/contentbox" element={<Contentbox />} />
        {/* <Route path="/dashboard" element={<Dashboard />}>
          <Route path="applications" element={<AllApplications />} />
          <Route path="events" element={<AdminEvent />}>
            <Route path="new" element={<NewEvent />} />
            <Route path=":id" element={<EditEvents />} />
            <Route path="edit" element={<AllEvents />} />
          </Route>
          <Route path="applications/:id" element={<AppDetails />} />
          <Route path="activity" element={<AdminActivity />} />
        </Route>
        <Route path="/login" element={<Loginpage />} />
      <Route path="/memberedit/:token" element={<MemberEdit />} /> */}
        <Route path="*" element={<NotFound />} />
        <Route path="/otp" element={<Otp />} />
        {/* <Route path="/createuser" element={<CreateUser />} />
        <Route path="/forgetpass" element={<ForgetPass />} />
        <Route path="/forget" element={<Forget />} /> */}
      </Routes>

      {/* <DashboardRoutes /> */}

      <Footer />
    </Router>
  );
}

export default App;
