import React from "react";
import vision from "./man-giving-bar-graph-presentation-using-high-technology-digital-pen (1).jpg";

const Vision = () => {
  return (
    // <div id="vision">
    //   <div id="leftvision">
    //     <h1 className="heading"><p className="bluec heading">Our</p> Vision</h1>
    //     <p className="content">
    //     To be the leading body to represent and promote <p className="bluec">Startups / Innovators</p> and <p className="bluec">Entrepreneurs</p> in Uttarakhand
    //     </p>
    //   </div>
    //   <div id="rightvision"><img src={vision} alt="" /></div>
    // </div>
    <div id="vision">
      <div id="leftvision">
        <div id="visionContent">
          <p className=" heading">
            <p className="bluec">Our</p> Vision
          </p>
          <p className="maincontent mid_text">
            To be the leading body to represent and promote{" "}
            <p className="bluec">Startups / Innovators</p> and
            <p className="bluec">Entrepreneurs</p> in Uttarakhand
          </p>
        </div>
      </div>
      <div id="rightvision">
        <img src={vision} alt="" />
      </div>
    </div>
  );
};

export default Vision;
