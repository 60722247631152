import React from "react";
import "./AboutUs.scss";
// import mission from "../Home/message.png"
import About from "./About";
import About2 from "./About2";

const AboutUs = () => {
  return (
    <div>
      <div className="headerdist"></div>

      {/* ----------------------------------------------------------------------------------------------------
    ---------------------------------------MISSION ABOUT US -------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------------*/}
      <About />
      <About2 />
    </div>
  );
};

export default AboutUs;
