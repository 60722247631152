import React from "react";
import about from "./About.png";

const About = () => {
  return (
    <div id="about">
      <p className="heading">
        <p className="bluec">About</p> Us
      </p>
      <div id="mainabout">
        <div id="leftabout" className="highlight_text">
          <div>
            The Federation of Uttarakhand Entrepreneurs (FedUK) is a
            non-governmental trade association and advocacy group based in
            India. It was established in 2021 by the Team of Uttarakhand
            Entrepreneurs. The Federation is aiming to have membership of over
            5000 entrepreneurs. It is headquartered in the Uttarakhand, India
            and has regional office in New Delhi, India.
          </div>
        </div>
        <div id="rightabout">
          <img src={about} alt="" />
        </div>
      </div>
    </div>
  );
};

export default About;
