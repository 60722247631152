import React from "react";
import "./Home.scss";
import Key from "./Home/Key";
import Message from "./Home/Message";
import Mission from "./Home/Mission";
import PhotoCarousel from "./Home/PhotoCarousel";
import Vision from "./Home/Vision";
import "./Home/global.scss";

const Home = () => {
  // console.log(port);
  return (
    <div>
      <Message />
      <Key />
      <Vision />
      <Mission />
      {/* <PhotoCarousel /> */}
    </div>
  );
};

export default Home;
