import React from "react";

const Key = () => {
  return (
    <div id="mqkey">
      {" "}
      <div className="keyHeading">
        <p className="heading">
          <p className="bluec">Key</p> Objectives
        </p>
      </div>
      <div id="mqmainkey">
        <div className="outerProKeymq">
          <div className="outerKeymq">
            {" "}
            <div className="blueKeymq" id="key1"></div>
            <div className="mqsubhead mid_text">
              <b>Debate</b>
            </div>
            <div className="mqtext highlight_text">
              Debate corporate law issues: Governance, Laws, Regulations,
              Competition, Bills.
            </div>
          </div>
          <div className="outerKeymq">
            {" "}
            <div className="yellowKeymq" id="key2"></div>
            <div className="mqsubhead mid_text">
              <b>Represent</b>
            </div>
            <div className="mqtext highlight_text">
              Represent Uttarakhand Entrepreneurs through constructive
              engagement with government agencies and forums.
            </div>
          </div>
        </div>

        <div className="outerProKeymq">
          <div className="outerKeymq">
            {" "}
            <div className="yellowKeymq" id="key4"></div>
            <div className="mqsubhead mid_text">
              <b>Advocate</b>
            </div>
            <div className="mqtext highlight_text">
              Catalyst for growth-centric laws, regulations, and opportunities.
            </div>
          </div>{" "}
          <div className="outerKeymq">
            {" "}
            <div className="blueKeymq" id="key3"></div>
            <div className="mqsubhead mid_text">
              <b>Develop</b>
            </div>
            <div className="mqtext highlight_text">
              Develop cooperative relationship, identify obstacles, create
              enabling environment for Uttarakhand Entrepreneurs.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Key;
