import React from "react";
import mission from "./mission.jpg";
const Mission = () => {
  return (
    <div>
      {" "}
      <div id="missionaboutus">
        <div id="leftmau">
          <p id="missionqoute">
            <p className="bluec heading" id="missionmau">
              Our Mission
            </p>
            <br />
            <p className="main_heading"> Uniting Visionaries</p>
          </p>
          <p id="missionmessage" className="normal_text">
            To bridge Innovators, Entrepreneurs and Investors with Industry,
            Education, Markets and Government Agencies Value-adding the New
            Economy through Innovation and Entrepreneurship to Nurture and
            Promote a New Generation of Innovators and Entrepreneurs in
            Uttarakhand
          </p>
        </div>
        <div id="rightmau">
          <img src={mission} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Mission;
