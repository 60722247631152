import React from "react";
import "./Footer.scss";
import logo from "../Header/2.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div id="footer">
        <div id="insideContent">
          <div id="smalltext" className="footer_text">
            <img src={logo} alt="" id="footerHeader" />
            <br />
            <br />A diverse team of experienced entrepreneurs and industry
            experts with a shared vision to accelerate entrepreneurial growth.
          </div>

          <div id="quicklinks" className="footer_text">
            <h2>Quick links</h2> <br />
            <ul>
              <li className="footer_text">
                <Link to="/">Home</Link>
              </li>
              <li className="footer_text">
                <Link to="/about">About Us</Link>
              </li>

              <li className="footer_text">
                <Link to="/event">Events</Link>
              </li>

              <li className="footer_text">
                <Link to="/member">Join Us</Link>
              </li>
            </ul>
          </div>

          <div id="contactus" className="footer_text">
            <h2>Contact Us</h2> <br />
            +91-7303787221
            <br /> <br />
            E-113, 2nd & 3rd Floor, Okhla, Phase III, New Delhi-110020, India.
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
