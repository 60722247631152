import React from "react";
import ReactLoading from "react-loading";

const Loading = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <ReactLoading
        type="cylon"
        width="30vw"
        color="black"
        height="fit-content"
      />
    </div>
  );
};

export default Loading;
