import React from "react";
import idea from "./message.png";
import bulb from "./bulb.png";
import "./Message.scss";

const Message = () => {
  return (
    <div id="message">
      <div id="leftMessage">
        <div id="innerleftmessage">
          <p id="topmessage" className="bluec mid_text">
            <img src={bulb} alt="" /> &ensp; Investing in Ideas
          </p>
          <p id="mainmessage" className="main_heading">
            Connecting Visionaries, Fueling Entrepreneurial Growth
          </p>
          <p id="chotamessage" className="highlight_text">
            Where brilliant minds converge, propelling innovation and prosperity
          </p>
        </div>
      </div>
      <div id="rightMessage">
        <img src={idea} alt="" />
      </div>
    </div>
  );
};

export default Message;
