import axios from "axios";
import React from "react";
import { useState } from "react";
import OtpInput from "react-otp-input";

const Otp = ({ email }) => {
  const [otp, setOtp] = useState("");
  const verifyOtp = () => {
    axios
      .post("https://36fb-49-36-183-75.ngrok-free.app/api/verify-otp", {
        email,
        otp,
      })
      .then((result) => console.log(result));
  };

  return (
    <div>
      <OtpInput
        className="otpver"
        value={otp}
        onChange={setOtp}
        numInputs="5"
        renderInput={(props) => <input {...props} />}
        renderSeparator={<span>-</span>}
      />
      <button type="button" onClick={verifyOtp}>
        Verify
      </button>
    </div>
  );
};

export default Otp;
