import React from "react";
import "./Event.scss";

const EventCard = ({ photo, name, id, date, time, status }) => {
  const month = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  console.log(photo);

  const link =
    status === 1
      ? `http://127.0.0.1:3000/Upevents/${id}`
      : `http://127.0.0.1:3000/past/${id}`;
  return (
    <div id={name} className="eventcard">
      <div id="eventphoto">
        <img src={photo} alt="" />
      </div>
      <div id="eventinfo">
        <div id="eventTime" className="highlight_text">
          {" "}
          <div id="eventMonth">{month[2]}</div>
          <div id="eventDate">{date[8] + date[9]}</div>
        </div>
        <div id="eventCname" className="mid_text">
          {name}
          <br />
          <p>{time}</p>
        </div>
      </div>
      <div id="eventread">
        <a href={link}>
          {" "}
          <button id="eventbutton" className="normal_text">
            Read More
          </button>
        </a>
      </div>
    </div>
  );
};

export default EventCard;
