import React from "react";
import about2 from "./about2.svg";

const About2 = () => {
  return (
    <div id="about2">
      <div id="leftabout2">
        <img src={about2} alt="" />
      </div>
      <div id="rightabout2" className="highlight_text">
        <p>
          The aim of Federation of Uttarakhand Entrepreneurs is to become the
          influential organization to represent Entrepreneurs of Uttarakhand in
          Uttarakhand, India and at Global level. FedUK is a non-government,
          not-for-profit organization. The Federation draws its membership from
          the Startups, SMEs, MSME, etc. and wants to promote entrepreneurship
          activities for self-employment and overall growth of Uttarakhand.
        </p>
      </div>
    </div>
  );
};

export default About2;
