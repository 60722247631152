import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Geocode from "react-geocode";
import { useNavigate } from "react-router-dom";

const NewEvent = () => {
  const [photo, setPhoto] = useState([]);
  const [address, setAddress] = useState("");
  const [dpPhoto, setDpPhoto] = useState();
  const navigate = useNavigate();
  const [value, setValue] = useState({
    name: "",
    eventDate: "",
    eventTime: "",
    venueLat: "",
    venueLong: "",
    status: "1",
    venue: "",
  });
  const photoRef = useRef();
  const dpRef = useRef();
  Geocode.setApiKey("AIzaSyDS9TIK-xpgCZUkkpfkLP0znICaXVSvIhY");
  Geocode.setLanguage("en");
  const changeDp = (e) => {
    console.log(e.target.files.length);
    if (e.target.files.length)
      setDpPhoto(URL.createObjectURL(e.target.files[0]));
    else setDpPhoto("");
  };
  const changePhoto = (e) => {
    const a = [];
    for (let p of e.target.files) {
      a.push(URL.createObjectURL(p));
    }
    setPhoto(a);
  };
  const changeHandler = (e) => {
    setValue({ ...value, [e.target.id]: e.target.value });
  };
  const submitHsndler = (e) => {
    e.preventDefault();
    const dataForm = new FormData();
    for (const file of photoRef.current.files) {
      // console.log(file);
      dataForm.append("event", file);
    }
    dataForm.append("dp", dpRef.current.files[0]);
    dataForm.append("foo", JSON.stringify(value));
    console.log(value);
    axios
      .post("http://192.168.1.106:9000/event/new", dataForm, {
        withCredentials: true,
      })
      .then(({ data }) => {
        if (!data.auth) {
          navigate("/login");
        }
        // console.log(data);
        navigate(`/dashboard/events/${data.id}`);
      })
      .catch((e) => alert(e));
  };
  useEffect(() => {
    if (address && address.label !== value.venue) {
      console.log(address.label);
      Geocode.fromAddress(address.label)
        .then(({ results }) => {
          // console.log(results[0].geometry.location.lat);W
          setValue({
            ...value,
            venueLat: results[0].geometry.location.lat,
            venueLong: results[0].geometry.location.lng,
            venue: address.label,
          });
        })
        .catch((e) => console.log(e));
    }
  }, [address, value]);

  return (
    <div className="normal_text">
      <form
        id="neweventform"
        action=""
        onSubmit={submitHsndler}
        encType="multipart/form-data"
      >
        <input
          type="text"
          id="name"
          placeholder="name"
          onChange={changeHandler}
          value={value.name}
          required
        />
        <input
          type="date"
          id="eventDate"
          placeholder="YYYY-MM-DD"
          onChange={changeHandler}
          value={value.eventDate}
          required
        />
        <input
          type="text"
          id="eventTime"
          placeholder="eventTime"
          onChange={changeHandler}
          value={value.eventTime}
          required
        />
        <input
          type="text"
          id="venueLat"
          placeholder="Lat"
          onChange={changeHandler}
          value={value.venueLat}
          style={{ cursor: "not-allowed" }}
          disabled
          required
        />
        <input
          type="text"
          id="venueLong"
          placeholder="long"
          onChange={changeHandler}
          value={value.venueLong}
          style={{ cursor: "not-allowed" }}
          disabled
        />
        <label>Event Photos</label>
        <input
          type="file"
          ref={photoRef}
          multiple
          name="event"
          id=""
          onChange={changePhoto}
        />
        <label htmlFor="">Status</label>
        <select id="status" onChange={changeHandler} value="1">
          <option value="1">Upcoming</option>
          <option value="2">Past</option>
        </select>

        <label>Display Photos</label>
        <input type="file" ref={dpRef} name="dp" id="" onChange={changeDp} />
        <label htmlFor="">Venue</label>
        <GooglePlacesAutocomplete
          className="venuenewevent"
          apiKey="AIzaSyDS9TIK-xpgCZUkkpfkLP0znICaXVSvIhY"
          selectProps={{ address, onChange: setAddress }}
        />

        <button>Submit</button>
      </form>
      <div className="dpphotoreview">
        <img src={dpPhoto} alt="" />
      </div>
      <div id="eventphotoreview">
        {photo.map((phoo, index) => (
          <div key={index}>
            <img src={phoo} alt="" />{" "}
            {/* <button
              type="button"
              id="eventphotobutton"
              onClick={() => {
                setPhoto(photo.filter((item) => item != phoo));
                delete photoRef.current.files.item(index);
                console.log(photoRef.current.files);
              }}
            >
              delete
            </button> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewEvent;
