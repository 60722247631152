import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import OtpInput from "react-otp-input";
import "./Membership.scss";
import formimg from "./registimg.jpg";

const NewMember = () => {
  const [email, setEmail] = useState(false);
  const fileInputRef = useRef();
  const profileInputRef = useRef();
  const [showVerify, setShowVerify] = useState(false);
  const [verify, setVerify] = useState(false);
  const [otp, setOtp] = useState("");
  const checkEmail = async (e) => {
    if (e.target.value) {
      setEmail(false);
      setVerify(false);
      document.getElementById("emailform").innerText = "";
      setFormData({ ...formData, email: e.target.value });
      const { data } = await axios
        .get(
          `https://36fb-49-36-183-75.ngrok-free.app/api/checkEmail/${e.target.value}`
        )
        .catch((e) => {
          alert(e);
          console.log(e);
        });
      console.log(data.length);
      if (!data.length) {
        setEmail(true);
        document.getElementById("emailform").innerText = "email is unique";
      } else {
        console.log(document.getElementById("emailform"));
        document.getElementById("emailform").innerText = "email already exists";
      }
    } else {
      setFormData({ ...formData, email: e.target.value });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (verify && email) {
      const formData2 = new FormData();
      formData2.append("foo", JSON.stringify(formData));
      formData2.append("panCard", fileInputRef.current.files[0]);
      formData2.append("profilePhoto", profileInputRef.current.files[0]);
      axios
        .post(
          "https://36fb-49-36-183-75.ngrok-free.app/api/member/new",
          formData2
        )
        .catch((e) => alert(e));
    } else {
      alert("email is either not verified or it already exists in database");
    }
  };

  const changeHandler = (e) => {
    const { placeholder, value } = e.target;
    setFormData({ ...formData, [placeholder.toLowerCase()]: value });
    if (placeholder === "email") setEmail(false);
  };

  const setMemberTier = (e) => {
    setFormData({ ...formData, memTier: e.target.value });
  };
  const [formData, setFormData] = useState({
    name: "",
    aadhar: "",
    gst: "",
    email: "",
    company: "",
    duration: "",
    memTier: "",
    pan: "",
    number: "",
  });

  const sendOtp = (e) => {
    axios
      .post("https://36fb-49-36-183-75.ngrok-free.app/api/generate-otp", {
        email: formData.email,
      })
      .then(() => {
        setShowVerify(true);
      })
      .catch((e) => {
        alert(e);
      });
  };

  const verifyOtp = () => {
    axios
      .post("https://36fb-49-36-183-75.ngrok-free.app/api/verify-otp", {
        email: formData.email,
        otp,
      })
      .then(({ data }) => {
        if (data) {
          alert("Otp Verified");
        } else {
          alert("Wrong Otp");
        }
        setVerify(data);
      });
  };

  useEffect(() => {
    if (verify && email)
      document.getElementById("member-submit").disabled = false;
    else {
      document.getElementById("member-submit").disabled = true;
    }
  }, [email, verify]);

  return (
    <div id="newMember">
      <div id="form_div" className="normal_text">
        <img src={formimg} alt="" id="formimg" />
        <form
          id="memberform"
          onSubmit={submitHandler}
          encType="multipart/form"
          action="/"
        >
          <label>Full Name</label>
          <input
            type="text"
            value={formData.name}
            onChange={changeHandler}
            placeholder="Name"
          />
          <label>Aadhar Number</label>
          <input
            type="text"
            placeholder="Aadhar"
            value={formData.aadhar}
            onChange={changeHandler}
          />
          <label>PAN Number</label>
          <input
            type="text"
            placeholder="PAN"
            value={formData.pan}
            onChange={changeHandler}
          />
          <label>Email </label>
          <input
            id="member-id"
            type="Email"
            value={formData.email}
            placeholder="email"
            onChange={checkEmail}
          />
          <p id="emailform"></p>{" "}
          <button type="button" onClick={sendOtp} className="newmemformbtn">
            Verify
          </button>
          <div style={showVerify ? { display: "flex" } : { display: "none" }}>
            <div>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs="5"
                renderInput={(props) => <input {...props} />}
                renderSeparator={<span>-</span>}
              />
              <button
                type="button"
                onClick={verifyOtp}
                className="newmemformbtn"
              >
                Verify
              </button>
            </div>
          </div>
          <label>Phone No.</label>
          <input
            type="number"
            value={formData.number}
            placeholder="Number"
            onChange={changeHandler}
          />
          <label>GSTIN</label>
          <input
            type="text"
            value={formData.gst}
            placeholder="gst"
            onChange={changeHandler}
          />
          <label htmlFor=""> Membership Tier</label>
          <select
            value={formData.memTier}
            placeholder="memTier"
            onChange={setMemberTier}
          >
            <option value="select">Select</option>
            <option value="associate">Associate Membership</option>
            <option value="silver">Silver Membership</option>
            <option value="gold">Gold Membership</option>
            <option value="platinum">Platinum Membership</option>
          </select>
          <label htmlFor="">Pan Card</label>
          <input
            className="uploaddoc"
            type="file"
            name="panCard"
            id=""
            value={formData.file}
            placeholder="panCard"
            ref={fileInputRef}
          />
          <label htmlFor="">Profile Photo</label>
          <input
            className="uploaddoc"
            type="file"
            name="profilePhoto"
            ref={profileInputRef}
          />
          <button id="member-submit" className="newmemformbtn" disabled>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewMember;
