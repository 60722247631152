import React from "react";
import portfolioicon from "./dashboardicons/portfolio.png";

// let iconheading = "Portfolios Received";
// let iconsubheading = "200";

const Contentbox = ({
  iconheading = "Portfolios Received",
  iconsubheading = "200",
}) => {
  return (
    <>
      <div className="contentbox-main">
        <img
          src={portfolioicon}
          alt="Portfolio Icon"
          className="contentboxicon"
        />
        <h2 className="iconheading">{iconheading}</h2>
        <h3 className="iconsubheading">{iconsubheading}</h3>
      </div>
    </>
  );
};

export default Contentbox;
