import React from "react";
// import blueTick from "./blueTick.png";
// import greyTick from "./greyTick.png";
import benifits2 from "./benifits1.jpg";
import benifits1 from "./benifits2.jpg";

const MembershipPerks = () => {
  return (
    <div>
      <div className="heading" id="hdiv" style={{ textAlign: "center" }}>
        Benefits Of Membership
      </div>
      <div className="upperdivs" id="benefitup">
        <div className="normal_text" id="upleftbenefit">
          <ol>
            {" "}
            <li>
              <b>Professionalism:</b> No doubt this is the most positive reasons
              for every Uttarakhand Entrepreneur to join the FedUK. We will run
              professional development events such as workshops and webinars,
              seminars, events and perhaps publishes a periodical, all of which
              are designed to raise awareness of important issues facing its
              target audience.{" "}
            </li>
            <li>
              <b>Networking:</b> FedUK provides professional networking, by
              attending local events sponsored by my local organization will
              give us a chance to meet great people whom we would not have met
              otherwise.{" "}
            </li>{" "}
            <li>
              <b>Profile:</b> FedUK will provides you with an avenue to "give
              something back" to the profession, perhaps by presenting at one of
              its events or by authoring an article in its magazine. While this
              is an admirable thing to do, it is a win-win transaction for
              members as it also raises their profile in the professional
              community.{" "}
            </li>
            <li>
              {" "}
              <b>Recognition:</b> Many organizations also sponsor industry
              awards. FedUK will also give its focus and will also do alliances
              or collaboration with India organization like FICCI, Assochem etc
              other than big enterprise but also with the Global Organization,
              institutions. These awards are important because they shine a
              light on outstanding work that should be showcased but might
              otherwise have remained undiscovered.
            </li>
          </ol>
        </div>
        <div className="divofimg">
          <img src={benifits1} alt="" className="benifits1" />
        </div>
      </div>
      <div className="upperdivs" id="benefitdown">
        <div className="divofimg">
          <img src={benifits2} alt="" id="benifits1" />
        </div>
        <div className="normal_text" id="downleftben">
          The Strategic Priorities of FedUK and its Membership The first
          strategic imperative of a membership is to offer something unique to
          its members. What are the environmental conditions in my market? What
          are the hottest topics in my region? Which laws or regulations should
          I be mindful of? How much should I charge for my work? What do my
          peers get paid? The FedUK should be able to provide answers to these
          questions if we work together and build a strong and influential
          Federation. The second strategic imperative of a membership is to
          transform and adapt to continue to remain relevant to its members'
          needs. The current professional expects his or her membership to keep
          up to date of the latest trends and be on the front foot with
          innovative technologies. The viable future for FedUK continue to
          evolve to meet the needs of their members, because at the end of the
          day, it all boils down to return on investment. If each individual
          member feels that he or she is receiving value for money, then that is
          what really matters.
        </div>
      </div>

      <div className="heading" id="basicmem">
        Basic Memberships
      </div>
      <div id="primaryperks">
        <div id="associate">
          <div className="perkheading main_heading">
            <p>Associate Membership</p>
          </div>
          <div className="perk">
            <ul className="perksUL normal_text">
              {/* <img src={blueTick} alt="" className="imgclass" /> */}
              <li className="perklist liclass">Attend all seminars for free</li>

              <li className="perklist">
                {/* <img src={blueTick} alt="" /> */}
                Receive all news and updates
              </li>
              <li className="perklist">
                {/* <img src={blueTick} alt="" /> */}
                Receive support by Telephone
              </li>
              <li className="perklist">
                {/* <img src={blueTick} alt="" /> */}
                Participate in all FedUK events
              </li>
              <li className="perklist">
                {/* <img src={blueTick} alt="" /> */}
                Place logo on FedUK website
              </li>
              <li className="perklist">
                {/* <img src={blueTick} alt="" /> */}
                Attend all webinars for free
              </li>
              <li className="perklist">
                {/* <img src={blueTick} alt="" /> */}
                Receive go to market strategy
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="heading" id="premiummem">
        Premium Memberships
      </div>
      <div id="secperks">
        <div id="silver">
          <div className="perkheading main_heading">
            <p>Silver</p>
          </div>
          <div className="perk">
            <div className="prevbenefits normal_text">
              All benefits included in the associate tier and
            </div>
            <div className="perklistdiv">
              <ul className="perksUL normal_text">
                <li className="perklist blue">
                  {/* <img src={blueTick} alt="" /> */}
                  Recieve B2B contacts
                </li>
                <li className="perklist blue">
                  {/* <img src={blueTick} alt="" /> */}
                  Organize counterparts meet
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="gold">
          <div className="perkheading main_heading">
            <p>Gold</p>
          </div>
          <div className="perk">
            <div className="prevbenefits normal_text">
              All benefits included in the silver tier and
            </div>
            <div className="perklistdiv">
              {" "}
              <ul className="perksUL normal_text">
                <li className="perklist blue">
                  {/* <img src={blueTick} alt="" /> */}
                  Works closely on market entry
                </li>
                <li className="perklist blue">
                  {/* <img src={blueTick} alt="" /> */}
                  Place logo on materials
                </li>
                <li className="perklist blue">
                  {/* <img src={blueTick} alt="" /> */}
                  Travel with Delegation for meetings, seminars etc.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="platinum">
          <div className="perkheading main_heading">
            <p>Platinum</p>
          </div>
          <div className="platperk">
            <div className="prevbenefits normal_text">
              All benefits included in the gold tier and
            </div>
            <div className="perklistdiv">
              <ul className="perksUL normal_text">
                <li className="perklist blue">
                  {/* <img src={blueTick} alt="" /> */}
                  Travel with FedUK
                </li>
                <li className="perklist blue">
                  {/* <img src={blueTick} alt="" /> */}
                  Receive B2G contacts per sector
                </li>
                <li className="perklist blue">
                  {/* <img src={blueTick} alt="" /> */}
                  Brand Promotion through different sources
                </li>
                <li className="perklist blue">
                  {/* <img src={blueTick} alt="" /> */}
                  Attends meetings with CM, MPs, Mayors, Ministers, Secretary,
                  Foreign delegations etc.
                </li>
                <li className="perklist blue">
                  {/* <img src={blueTick} alt="" /> */}
                  Hand-to-hand work
                </li>
                <li className="perklist blue">
                  {/* <img src={blueTick} alt="" /> */}
                  Receive tenders, RFPs first
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipPerks;
