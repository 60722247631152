import React, { useState } from "react";
import CFphoto from "./CFphoto.png";
import axios from "axios";
// import logo from "/2.png";
const ContactForm = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    CFmessage: "",
  });
  const changeHandler = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    axios
      .post("https://36fb-49-36-183-75.ngrok-free.app/contact/", form)
      .catch((e) => alert(e));
  };
  return (
    <div id="contactForm">
      <div id="outerCF">
        <div id="CFleft">
          <div id="CFlogodiv">
            <img src="/2.png" alt="" id="logoCF" />
          </div>
          <div id="CFphotodiv">
            {" "}
            <img src={CFphoto} alt="" id="CFphoto" />
          </div>
        </div>
        <div id="CFright">
          <div id="CFheading">
            {" "}
            <p id="CF">Contact Us</p>
            <p id="CF247">24x7 Service</p>
          </div>
          <form action="submit" id="CFform" onSubmit={submitHandler}>
            <input
              type="text"
              id="name"
              placeholder="name"
              onChange={changeHandler}
            />

            <input
              id="email"
              type="email"
              placeholder="email"
              onChange={changeHandler}
            />

            <input
              id="phone"
              type="number"
              placeholder="Phone No."
              onChange={changeHandler}
            />

            <textarea
              name=""
              id="CFmessage"
              cols="22"
              rows="8"
              placeholder="Message...."
              onChange={changeHandler}
            ></textarea>

            <button id="CFbutton">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ContactForm;
