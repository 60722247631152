import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import venue from "./Vector.png";

import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const Upevents = () => {
  const { id } = useParams();

  const [eventData, setEventData] = useState();
  const [location, setLocation] = useState("");

  useEffect(() => {
    axios
      .get(`http://192.168.1.106:9000/event/${id}`)
      .then((r) => {
        console.log(r.data[0]);
        setEventData(r.data[0]);
      })
      .catch((e) => alert(e));
  }, [id]);
  useEffect(() => {
    eventData && console.log(eventData.venueLat, eventData.venueLong);
    // ? `https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14018.561961158028!2d${eventData.venueLong}!3d${eventData.venueLat}!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1f8ab86c668d%3A0xcdf3cb7434fc7ca0!2sNewGen%20IT%20solutions%20and%20Services!5e0!3m2!1sen!2sin!4v1694517164803!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade`

    let loca = eventData
      ? `https://www.google.com/maps/embed/v1/place?q=${eventData.venueLat},${eventData.venueLong}&key=AIzaSyDS9TIK-xpgCZUkkpfkLP0znICaXVSvIhY`
      : "";

    console.log(loca);
    setLocation(loca);
  }, [eventData]);

  return (
    <>
      {eventData ? (
        <div className="eventInfo">
          <img
            src={eventData.dp}
            alt="Upcoming events"
            className="Upeventsimg"
          />
          <div id="heresmaindiv">
            <div id={eventData.name} className="eventname mid_text">
              <div id="eventnamediv">
                {eventData.name}
                <br />
                by <span className="intext FedUK">FedUK</span>
              </div>
              <div className="intext" id="venue">
                <img id="venueimg" src={venue} alt="venue" />
                {eventData.Venue}
              </div>
              <div id="datetime">
                {eventData.eventDate.slice(0, 10)} <br /> {eventData.eventTime}
              </div>
            </div>
            <hr id="hrbetween" />
            <div id="outerdiv">
              <div className="mid_text" id="leftdiv">
                Share this event
                <div>
                  <FacebookShareButton url="www.x.com" className="logos">
                    <FacebookIcon size={32} />
                  </FacebookShareButton>
                  <TwitterShareButton url="hello" className="logos">
                    <TwitterIcon size={32} />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url="https://www.npmjs.com/package/react-share"
                    title="Hey See this amazing react library"
                    className="logos"
                  >
                    <WhatsappIcon size={32} />
                  </WhatsappShareButton>
                </div>
              </div>
              <div id="middiv">
                <iframe
                  id="locationmap"
                  title="link for map "
                  src={location}
                  width="600"
                  height="450"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                >
                  {" "}
                </iframe>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>loading</div>
      )}{" "}
    </>
  );
};

export default Upevents;

//
// https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14018.561961158028!2d77.2715834!3d28.5505238!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1f8ab86c668d%3A0xcdf3cb7434fc7ca0!2sNewGen%20IT%20solutions%20and%20Services!5e0!3m2!1sen!2sin!4v1694517511971!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade
