import React, { useEffect, useState } from "react";
import "./EventPage.scss";
import axios from "axios";
import EventCard from "../Home/EventCard";
import Loading from "../Loading";

const EventPage = () => {
  const [upData, setUpData] = useState(null);
  const upcomingData = async () => {
    axios
      .get("http://192.168.1.106:9000/event/upcoming")
      .then(({ data }) => {
        if (!(data.data.length === 0)) setUpData(data.data);
      })
      .catch((e) => console.log(e));
  };
  const [pastData, setPastData] = useState(null);
  const pastDataFunc = async () => {
    axios
      .get("http://192.168.1.106:9000/event/past")
      .then(({ data }) => {
        if (!(data.data.length === 0)) setPastData(data.data);
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    upcomingData();
    pastDataFunc();
  }, []);
  return (
    <div>
      {" "}
      {upData ? (
        <>
          <div className="eventheading heading">Upcoming Events</div>
          <div id="UpEvent" className="EventPageIn">
            {upData &&
              upData.map((event) => {
                console.log(upData);
                return (
                  <EventCard
                    name={event.name}
                    key={event.id}
                    date={event.eventDate}
                    time={event.eventTime}
                    id={event.id}
                    status={event.status}
                    photo={event.dp}
                  />
                );
              })}
          </div>
        </>
      ) : (
        <h1>No Upcoming Events</h1>
      )}
      {pastData ? (
        <>
          <div className="eventheading heading">Past Events</div>
          <div id="pastevent" className="EventPageIn">
            {pastData &&
              pastData.map((event) => {
                return (
                  <EventCard
                    photo={event.dp}
                    name={event.name}
                    key={event.id}
                    date={event.eventDate}
                    time={event.eventTime}
                    id={event.id}
                    status={event.status}
                  />
                );
              })}
          </div>
        </>
      ) : (
        <h1>No Past Events</h1>
      )}
    </div>
  );
};

export default EventPage;
